import { useCallback } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import { InputDateRangeValue } from '@/design_system/InputDate/InputDateRange';
import Stack from '@/design_system/Stack';
import Tabs from '@/design_system/Tabs';
import { Tab, TabList, TabPanel } from '@/design_system/Tabs/Tabs';
import IconAdd from '@/icons/Add.svg';
import { useCurrentOrganization, useCurrentSession } from '@/services/auth';
import { useDebouncedSearchParamState } from '@/utils/useDebouncedState';
import { useSearchParamState, useSyncSearchParams } from '@/utils/useSearchParams';

import { Invoices } from './Invoices/Invoices';
import { TransactionDestination } from './Transactions/components/filters/TransactionDestinationSelect';
import { TransactionOrigin } from './Transactions/components/filters/TransactionOriginSelect';
import { Transactions } from './Transactions/Transactions';

export const Accounting = () => {
  const { currentSession } = useCurrentSession();
  const [currentOrganization] = useCurrentOrganization();
  const { t } = useLingui();

  const [tab, setTab, syncTab] = useSearchParamState<string>('accounting', 'tab', '');
  const [page, setPage, syncPage] = useSearchParamState<number>('accounting', 'page', 1);
  const [transactionOrigin, setTransactionOrigin, syncTransactionOrigin] =
    useSearchParamState<TransactionOrigin>('accounting', 'transactionOrigin', { type: 'all' });
  const [transactionDestination, setTransactionDestination, syncTransactionDestination] =
    useSearchParamState<TransactionDestination>('accounting', 'transactionDestination', {
      type: 'all',
    });
  const [dateRange, setDateRange, syncDateRange] = useSearchParamState<InputDateRangeValue>(
    'accounting',
    'dateRange',
    { option: 'any' }
  );

  const resetPage = useCallback(() => setPage(1), [setPage]);
  const [search, debouncedSearch, debouncedSetSearch, syncSearch] =
    useDebouncedSearchParamState<string>('accounting', 'search', '', 500, resetPage);

  useSyncSearchParams([
    syncTab,
    syncPage,
    syncSearch,
    syncTransactionOrigin,
    syncTransactionDestination,
    syncDateRange,
  ]);

  const canCreateInvoice = currentSession?.hasPermission('create_invoice', {
    organizationId: currentOrganization?.id,
  });

  return (
    <div className="container container--list">
      <Stack gap="24px" style={{ height: '100%' }}>
        <Stack row alignItems="center">
          <h1 className="headline-200-bold" style={{ flex: 1 }}>
            <Trans id="accounting.title">Accounting</Trans>
          </h1>
          {canCreateInvoice && (
            <>
              <Button className="is-hidden-mobile" size="medium">
                <IconAdd />
                <Trans id="accounting.new-invoice.label">New invoice</Trans>
              </Button>
              <Button
                className="is-hidden-from-tablet"
                size="large"
                iconOnly
                ariaLabel={t({ id: 'accounting.new-invoice.label', message: 'New invoice' })}
                tooltip={t({ id: 'accounting.new-invoice.label', message: 'New invoice' })}
              >
                <IconAdd />
              </Button>
            </>
          )}
        </Stack>
        <Tabs
          gap="24px"
          selectedKey={tab}
          onSelectionChange={(tab) => {
            setTab(tab);
            setTransactionOrigin({ type: 'all' });
            setTransactionDestination({ type: 'all' });
            setPage(1);
          }}
          style={{ flex: 1, paddingBottom: 24 }}
        >
          <TabList>
            <Tab id="invoices">
              <Trans id="accounting.tabs.invoices">Invoices</Trans>
            </Tab>
            <Tab id="transactions">
              <Trans id="accounting.tabs.transactions">Transactions</Trans>
            </Tab>
            {canCreateInvoice && (
              <Tab id="service-lines">
                <Trans id="accounting.tabs.service-lines">Services to associate</Trans>
              </Tab>
            )}
          </TabList>
          <TabPanel id="invoices">
            <Invoices />
          </TabPanel>
          <TabPanel id="transactions" style={{ flex: 1 }}>
            <Transactions
              {...{
                page,
                setPage,
                search,
                debouncedSearch,
                debouncedSetSearch,
                transactionOrigin,
                setTransactionOrigin,
                transactionDestination,
                setTransactionDestination,
                dateRange,
                setDateRange,
              }}
            />
          </TabPanel>
          {canCreateInvoice && (
            <TabPanel id="service-lines">
              <Trans id="accounting.tabs.service-lines">Services to associate</Trans>
            </TabPanel>
          )}
        </Tabs>
      </Stack>
    </div>
  );
};

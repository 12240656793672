import { Trans } from '@lingui/react/macro';

import { useInvoices } from '@/models/invoice';

export const Invoices = () => {
  const { data: { invoices } = { invoices: [] } } = useInvoices();

  console.log(invoices);

  return <Trans id="accounting.tabs.invoices">Invoices</Trans>;
};

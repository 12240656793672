import { useQuery } from '@tanstack/react-query';

import { Endpoints } from '@/api';
import { useFetch } from '@/utils/fetch';
import { Currency } from '@/utils/number';

import { Model } from './model';

export class Invoice extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  number!: string;

  originOrganizationCountryId!: string | null;
  originWorkshopId!: string | null;
  destinationOrganizationCountryId!: string | null;
  destinationClientId!: string | null;

  amount!: number | null;
  currency!: Currency | null;
  issuedAt!: string | null;
  paymentDueAt!: string | null;
  submittedAt!: string | null;
  validatedAt!: string | null;
  paidAt!: string | null;

  paymentStrategy!: InvoicePaymentStrategy | null;
  issue!: string | null;

  status!: InvoiceStatus;

  transactionId!: string | null;

  createdAt!: string;
}

export const INVOICE_STATUS = [
  'draft',
  'pending-validation',
  'handled-by-accounting',
  'paid',
  'issue',
] as const;

export type InvoiceStatus = (typeof INVOICE_STATUS)[number];

export const INVOICE_PAYMENT_STRATEGY = [
  'store-cash-out',
  'prolong-payout',
  'handled-by-accounting-team',
  'prolong-payin', // Client payment via Prolong (only Stripe for now)
] as const;

export type InvoicePaymentStrategy = (typeof INVOICE_PAYMENT_STRATEGY)[number];

export const useInvoices = (params?: Endpoints['GET /invoices']['query']) => {
  const fetch = useFetch<Endpoints['GET /invoices']>();

  return useQuery({
    queryKey: ['invoices', params],
    queryFn: () => fetch('/invoices', params),
  });
};
